<template>
    <div style="display:inline-block">
        <GridLoader v-if="type=='grid'" :loading="true" :color="color" :size="size" />
        <RingLoader v-else-if="type=='ring'" :loading="true" :color="color" :size="size" />
        <PulseLoader v-else-if="type=='pulse'" :loading="true" :color="color" :size="size" />
        <ScaleLoader v-else-if="type=='scale'" :loading="true" :color="color" :size="size" />
        <SyncLoader v-else :loading="true" :color="color" :size="size" />
    </div>
</template>

<script>
import GridLoader from "vue-spinner/src/GridLoader.vue"
import RingLoader from "vue-spinner/src/RingLoader.vue"
import PulseLoader from "vue-spinner/src/PulseLoader.vue"
import ScaleLoader from "vue-spinner/src/ScaleLoader.vue"
import SyncLoader from "vue-spinner/src/SyncLoader.vue"

export default {
    props:{
        type: {default:"sync"},
        color: {default:"#333"},
        size: {default:"14px"},
    },
    components:{
        SyncLoader: SyncLoader,
        GridLoader: GridLoader,
        PulseLoader: PulseLoader,
        ScaleLoader: ScaleLoader,
        RingLoader: RingLoader,
    }
}
</script>